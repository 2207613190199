import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import Quote from "../../../components/quote"
import Morework from "../../../components/more-work"
import Layout from "../../../components/layout"

export default (props) => (  
  <Layout>
  <Helmet 
    bodyAttributes={{class:'work-example grey-four'}}
    title="JayJo Design | Jeffrey Jorgensen | Work | Mixpanel - Insights"
  />   
    <div>
      <section className="study">
        <div className="content">
          <div className="wrapper first">
            <p className="title-header grey-two">Mixpanel</p>
            <h1 className="study-header">Insights</h1>
          </div>
          <Img 
            fluid={props.data.hero.childImageSharp.fluid}
            placeholderClassName="screengrab full"
            title="The final UI design for Mixpanel Insights."
            alt="The final UI design for Mixpanel Insights."
            loading="eager"
          />
        </div>
        <div className="content split">
          <div className="wrapper first">
            <div className="two-col two-thirds">
              <div className="col one">
                <h1 className="purple">Mixpanel was heading for the enterprise.</h1>
                <p className="intro">We needed an extremely flexible and powerful data-analysis and reporting tool, completely reimagined and designed from the ground up.</p>
                <p>Mixpanel’s flagship report was growing increasingly incapable of supporting the company's growth and goals.</p>
                <p>As we moved up market into the enterprise, our users wanted the ability to see data from multiple sources (not just Mixpanel) in one centralized location.</p>
              </div>
              <div className="col two">
                <div className="purpleBg details">
                  <p className="title-header">Project Deets</p>
                  <ul>
                    <li>Year &#8212; <span>2016</span></li>
                    <li>Role &#8212; <span>Design Lead & PM</span></li>
                    <li>Skills &#8212; <span>UI / UX</span></li>
                  </ul>
                  <p className="title-header">Product Goals</p>
                  <ul>
                    <li className="diamond">Increase clarity to user</li>
                    <li className="diamond">Boost user engagement</li>
                    <li className="diamond">Update visual design</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="wrapper">
            <h1 className="purple">Where we started...</h1>
          </div>
          <Img 
            fluid={props.data.segmentation.childImageSharp.fluid}
            className="screengrab purple-shadow"
            alt="Segmentation - the flagship report of Mixpanel (in a different chrome)."
            title="Segmentation - the flagship report of Mixpanel (in a different chrome)."
          />
          <div className="caption">Segmentation - the flagship report of Mixpanel (in a different chrome).</div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">What do our customers want?</h1>
            <p>I wanted a baseline of wants and issues with Segmentation as it was, so I analyzed a ton of customer support and sales data.</p>
            <p>I gathered 5 years worth of user data from ZenDesk and Asana, put it into a spreadsheet and synthesized it to find related data.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/507c62d91774a279ca124cf374d43195/3ba25/spreadsheet%402x.png" target="_blank">
            <Img 
              fluid={props.data.spreadsheet.childImageSharp.fluid}
              className="screengrab purple-shadow" 
              alt="5 years worth of user data from ZenDesk and Asana, put it into a spreadsheet." 
              title="5 years worth of user data from ZenDesk and Asana, put it into a spreadsheet."
            />
          </a>
          <div className="caption">5 years worth of user data from ZenDesk and Asana, put it into a spreadsheet.</div>
          <div className="wrapper narrow-med">
            <p>Next, I used Mixpanel’s event analytics to find Segmentation power users. A power user was defined as follows:</p>
            <ul>
              <li className="diamond-purple">Used Segmentation at least once a day</li>
              <li className="diamond-purple">Was a Segmentation user for >= 1 year</li>
              <li className="diamond-purple">Had written into customer support at least once in that time frame</li>
            </ul>
            <p>The first point of contact with them was a survey to gather some general intel on their usage of Segmentation and determine if they’d be interested in working with me to design the product.</p>
            <p>After that, I interviewed customers to understand more about their survey answers and the issues they experienced with Segmentation.</p>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Iterate, iterate, iterate...</h1>
            <p>For nearly every element of Insights, I had our users extensively test wireframes or prototypes. I used a validation study and interview guide rooted in IDEO's Design Kit.</p>
          </div>
          <a className="image-link" href="/static/23e8f43fb60f0b97a13f97365aa45f58/3ba25/query-iteration%402x.png" target="_blank">
            <Img 
              fluid={props.data.queryIteration.childImageSharp.fluid}
              className="screengrab-container full"
              placeholderClassName="screengrab purple-shadow full"
              alt="Iterating on the Insights Query Builder." 
              title="Iterating on the Insights Query Builder."
            />
          </a>
          <div className="wrapper">
            <div className="two-col with-image left-side-image">
              <div className="col one">
                <p>Participants took part in a 60 minute session where they used the product and answered questions about their experience.</p>
                <p>I'd use a Trello board to collate the interview responses to find common themes.</p>
              </div>
              <div className="col two">
                <Img 
                  fluid={props.data.validation.childImageSharp.fluid}
                  className="screengrab purple-shadow" 
                  alt="I interviewed 5 users on nearly every element of Insights (this synthesis board was for the report legend)" 
                  title="I interviewed 5 users on nearly every element of Insights (this synthesis board was for the report legend)"
                />
                <div className="caption">I interviewed 5 users on nearly every element of Insights (this synthesis board was for the report legend)</div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Applying visual design.</h1>
            <p>Once we validated our designs with at least 80% of our users, we would begin to work on the visual treatment of the components and elements. The following is the final design of the Query Builder and Property Menu.</p>
          </div>
          <div className="wrapper">
            <a className="image-link" href="/static/5c80114ec1ea65ad66f84781559bdc51/93a43/builder-and-menu%402x.png" target="_blank">
              <Img 
                fluid={props.data.builder.childImageSharp.fluid}
                className="screengrab full" 
                alt="The final design for the Query Builder and Property Menu in Insights." 
                title="The final design for the Query Builder and Property Menu in Insights."
              />
            </a>
          </div>
          <div className="wrapper narrow-med first">
            <h1 className="purple">Give developers what they want.</h1>
            <p>Our EPD team hadn't yet developed the Mixpanel Design System. While our engineers were incredible, I wasn't going to leave anything up to chance. Plus, engineering loved when we'd think through edge cases.</p>
            <p>I tirelessly thought through every scenario and permutation for the Insights menu and provided engineering with the following Sketch file so they could build the menu right, and only once.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/6652f9123d0a8089c655830598765d00/3ba25/menu-permutations%402x.png" target="_blank">
            <Img 
              fluid={props.data.permutations.childImageSharp.fluid}
              className="screengrab-container full"
              placeholderClassName="screengrab purple-shadow full"
              alt="Developer handoff to showcase the multiple permutations and states of the Insights menu." 
              title="Developer handoff to showcase the multiple permutations and states of the Insights menu."
            />
          </a>
          <div className="caption">Developer handoff to showcase the multiple permutations and states of the Insights menu.</div>
        </div>
        <div className="content">
          <div className="wrapper">
            <h1 className="purple">The final product.</h1>
          </div>
          <Img 
            fluid={props.data.finalUI.childImageSharp.fluid}
            className="screengrab-container full"
            placeholderClassName="screengrab purple-shadow full"
            alt="The final UI design for Mixpanel Insights." 
            title="The final UI design for Mixpanel Insights."
          />
        </div>
        <div className="content">
          <div className="wrapper first">
            <h1 className="purple">So how'd we do?</h1>
            <p>After shipping the product we closely monitored user-engagement and sought feedback from the interview group. The overall response was very positive, as were the results of the product:</p>
            <div className="three-col thirds">
              <div className="col">
                <div className="box">
                  <p className="result">50</p>
                  <p className="grey-two small centered">Average percentage of time cut off of queries due to UI changes and increased performance</p>
                </div>
              </div>
              <div className="col">
                <div className="box">
                  <p className="result">DS</p>
                  <p className="grey-two small centered">Rules and guidelines were written for each and every primitive and component in the Insights UI which laid the foundation and made the case for the Mixpanel Design System.</p>
                </div>
              </div>
              <div className="col">
                <div className="box">
                  <p className="result">4</p>
                  <p className="grey-two small centered">Number of months it took to break 51% (without marketing) to reach 51% feature-share which lead to the sunsetting of Segmentation.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <Quote
              quoteText="Jeff is really good at putting the user first. He always tries to solve the problem with the customer in mind and actually seeks out direct customer feedback with research as much as possible. He tries to truly solve the customer problem rather than trying to make designs that just look good."
              subject="- Engineering Colleague, 2016"
            />
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <div className="done-differently">
              <h1>Things I would do differently</h1>
              <p className="intro">Overall I was satisfied the team’s work, but I believe we could have done some things differently.</p>
              <ul>
                <li className="diamond-purple">Shorten product iteration cycles — having to both design and PM the project lead to some inefficiencies in scheduling/managing.</li>
                <li className="diamond-purple">Prototype earlier — a lot of the interactions were difficult to show in static form and could have benefitted from a small prototype.</li>
                <li className="diamond-purple">Established MP’s visual language first — Insights was being built in parallel to defining Mixpanel’s new visual design language so there was a lot of unnecessary back-and-forth between UX and visual design.</li>
              </ul>
            </div>
          </div>
        </div>
        <Morework 
          prevURL="/work/mixpanel/design-system"
          prevWorkTitle="Mixpanel Design System"
          nextURL="/work/mixpanel/email-ios"
          nextWorkTitle="Mixpanel Email Digests"
          purpleText="Have a project?"
          buttonText="Contact Me"
        />
      </section>
    </div>
  </Layout>
)

// Create a fragment for all images on the page
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
        src
      }
    }
  }
`

// Get the Site Meta Data & JayJo Links
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hero: file(relativePath: {eq: "work/mixpanel/insights/insights-ui-final@2x.png"}) {
      ...fluidImage
    }
    segmentation: file(relativePath: {eq: "work/mixpanel/insights/segmentation@2x.png"}) {
      ...fluidImage
    }
    spreadsheet: file(relativePath: {eq: "work/mixpanel/insights/spreadsheet@2x.png"}) {
      ...fluidImage
    }
    queryIteration: file(relativePath: {eq: "work/mixpanel/insights/query-iteration@2x.png"}) {
      ...fluidImage
    }
    validation: file(relativePath: {eq: "work/mixpanel/insights/validation-study@2x.png"}) {
      ...fluidImage
    }
    builder: file(relativePath: {eq: "work/mixpanel/insights/builder-and-menu@2x.png"}) {
      ...fluidImage
    }
    permutations: file(relativePath: {eq: "work/mixpanel/insights/menu-permutations@2x.png"}) {
      ...fluidImage
    }
    finalUI: file(relativePath: {eq: "work/mixpanel/insights/insights-ui-final@2x.png"}) {
      ...fluidImage
    }
  }
`